"use client";
import DarkCard from "containers/DarkCard";
import { GQLBusinessIdeaType, GQLQuiz } from "graphql/generated-types";
import dynamic from "next/dynamic";
import Question from "./Question";
import ButtonPanel from "./ButtonPanel";
import { useParams } from "next/navigation";
import { useProfileStore } from "utils/store";
import { useEffect } from "react";
const PetalGraph = dynamic(() => import("components/petalGraph/PetalGraph"), { ssr: false }); // no canvas on the server

type Props = {
	quiz: GQLQuiz;
	ideaData: GQLBusinessIdeaType;
	nextQuizURL: string;
	profileId: number;
};

const QuizBody: React.FC<Props> = ({ quiz, nextQuizURL, ideaData, profileId }) => {
	const params = useParams<{ ideaId: string }>();
	const { addProfile, getProfile } = useProfileStore();

	useEffect(() => {
		if (ideaData.profiles) {
			for (let profileData of ideaData?.profiles) {
				addProfile(profileData);
			}
		}
	}, [addProfile, ideaData.profiles]);
	const profile = getProfile(profileId);

	return (
		<div className="grid lg:grid-cols-2 lg:gap-24">
			<div className="flex flex-col gap-24">
				{quiz.questions.map((question) => (
					<Question key={question.id} {...question} profileId={profileId} />
				))}
			</div>

			<div className="hidden lg:block">
				<div className="sticky top-24">
					<DarkCard>
						<PetalGraph data={profile?.scores || []} />
					</DarkCard>
				</div>
			</div>
			{!nextQuizURL ? (
				<div className="mt-24 lg:w-2/3 lg:col-start-2 lg:place-self-end">
					<ButtonPanel
						href={`/idea/${params.ideaId}/rating`}
						text="View Commerical Feasibility Rating"
						className="!bg-charcoal"
					/>
				</div>
			) : (
				<div className="mt-24 lg:w-2/3 lg:col-start-2 lg:place-self-end">
					<ButtonPanel href={nextQuizURL} text="Next quiz" className="!bg-charcoal" />
				</div>
			)}
			<div className="mt-6 lg:mt-24 lg:w-2/3 lg:row-start-2">
				<ButtonPanel href={`/idea/${params.ideaId}`} text="Back to dashboard" reverse />
			</div>
		</div>
	);
};

export default QuizBody;
