"use client";
import { useEffect, useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

interface Props {
	progress: number;
	total: number;
	description?: string[];
	numberColour?: string;
	small?: boolean;
}

const ProgressChart = ({ progress, total, description, numberColour = "#F4F1EB", small = false }: Props) => {
	const [resize, setResize] = useState(0); // State to trigger rerender

	useEffect(() => {
		let debounceTimer: NodeJS.Timeout;

		const handleResize = () => {
			clearTimeout(debounceTimer);
			debounceTimer = setTimeout(() => {
				setResize((prev) => prev + 1); // Trigger rerender
			}, 200);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
			clearTimeout(debounceTimer);
		};
	}, []);

	const data = [
		{ name: "A", value: progress, color: "#AC70B8" },
		{ name: "B", value: total - progress, color: "#3D3D3C" },
	];

	return (
		<div className="w-full aspect-square" key={resize}>
			<ResponsiveContainer>
				<PieChart>
					<text
						x={"50%"}
						y={"60%"}
						dy="0.25em"
						textAnchor="middle"
						fill={numberColour}
						style={{
							fontSize: small ? "200%" : "500%",
							fontFamily: "Work Sans",
						}}
					>
						{progress}
					</text>
					{description &&
						description.map((text, index) => (
							<text
								key={index}
								x={"50%"}
								y={"60%"}
								dy={`${index + 2.8}em`}
								textAnchor="middle"
								fill={"#F4F1EB"}
								style={{
									fontStyle: "italic",
									fontSize: "14px",
								}}
							>
								{text}
							</text>
						))}
					<Pie
						dataKey="value"
						startAngle={220}
						endAngle={-40}
						data={data}
						cx={"50%"}
						cy={"60%"}
						innerRadius={"75%"}
						outerRadius={"100%"}
						fill="#8884d8"
						stroke="none"
					>
						{data.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={entry.color} />
						))}
					</Pie>
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
};

export default ProgressChart;
