import clsx from "clsx";
import DarkCard from "containers/DarkCard";
import { AnswerQuestionDocument, GQLAnswerChoicesEnum, GetQuestionAnswerDocument } from "graphql/generated-types";
import dynamic from "next/dynamic";
import { useParams } from "next/navigation";
import { FC, useEffect, useState } from "react";
import { useMedia } from "react-use";
import { clientRequest } from "utils/request";
import { clientRevalidate } from "utils/revalidate";
import { useProfileStore } from "utils/store";
import ButtonPanel from "./ButtonPanel";

const PetalGraph = dynamic(() => import("components/petalGraph/PetalGraph"), { ssr: false }); // no canvas on the server

interface Props {
	id: number;
	prompt: string;
	answerA: string;
	answerB: string;
	answerC: string;
	answerD: string;
	answerE: string;
	profileId: number;
}

interface AnswerButtonProps {
	answer: string;
	clickHandler: () => void;
	isActive: boolean;
	isMobile: boolean;
}

const AnswerButton: React.FC<AnswerButtonProps> = ({ answer, isActive, clickHandler, isMobile }) => {
	// Split on dash, en dash, or em dash
	const answerSplit = answer.split(/-|–|—/);
	const answerText = answerSplit[0];
	// rejoin any dashes after the first
	const answerRest = answerSplit.slice(1).join("-");

	const answerTextComponent = (
		<div className="flex gap-1 text-left">
			<span className={clsx("antialiased font-bold", answerRest && "whitespace-nowrap")}>{answerText}</span>
			{answerRest && (
				<>
					<span>—</span>
					<span className="italic">{answerRest}</span>
				</>
			)}
		</div>
	);

	if (isMobile) {
		return (
			<button
				onClick={clickHandler}
				className={clsx(
					"p-2 text-left rounded-lg bg-charcoal-light border-2 text-14px",
					isActive ? "border-[#B959B3]" : "border-transparent"
				)}
			>
				{answerTextComponent}
			</button>
		);
	}

	return (
		<ButtonPanel
			text={answerTextComponent}
			onClick={clickHandler}
			className={isActive ? "border-[#B959B3] border-2" : ""}
			hideArrow
		/>
	);
};

const MultipleChoice: FC<Props> = ({
	id: questionId,
	prompt,
	answerA,
	answerB,
	answerC,
	answerD,
	answerE,
	profileId,
}) => {
	const { ideaId } = useParams<{ ideaId: string }>();
	const [selectedAnswer, setSelectedAnswer] = useState<GQLAnswerChoicesEnum | null | undefined>(null);
	const [initialAnswer, setInitialAnswer] = useState<GQLAnswerChoicesEnum | null | undefined>(null);
	const { updateScore, getProfile } = useProfileStore();
	const isMobile = useMedia("(max-width: 1023px)", false);
	const profile = getProfile(profileId);

	useEffect(() => {
		async function getInitialAnswer() {
			const { data } = await clientRequest(GetQuestionAnswerDocument, {
				ideaId: parseInt(ideaId),
				questionId: questionId,
			});
			setInitialAnswer(data?.questionAnswer);
		}
		getInitialAnswer();
	}, [questionId, ideaId]);

	const handleButtonClick = (answer: GQLAnswerChoicesEnum) => {
		setSelectedAnswer(answer);
		let scoreValue = 0;
		switch (answer) {
			case GQLAnswerChoicesEnum.A:
				scoreValue = 5;
				break;
			case GQLAnswerChoicesEnum.B:
				scoreValue = 4;
				break;
			case GQLAnswerChoicesEnum.C:
				scoreValue = 3;
				break;
			case GQLAnswerChoicesEnum.D:
				scoreValue = 2;
				break;
			case GQLAnswerChoicesEnum.E:
				scoreValue = 1;
				break;
		}
		updateScore(profileId, questionId, scoreValue);
	};

	useEffect(() => {
		async function answerQuestion(selectedAnswer: GQLAnswerChoicesEnum) {
			await clientRequest(AnswerQuestionDocument, {
				ideaId: parseInt(ideaId),
				questionId: questionId,
				answer: selectedAnswer,
			});
		}
		if (selectedAnswer) {
			answerQuestion(selectedAnswer);
			clientRevalidate(`/idea/${ideaId}`); // update totals on idea landing
		}
	}, [selectedAnswer, ideaId, questionId]);

	let currentAnswer = selectedAnswer || initialAnswer;

	return (
		<DarkCard className="text-beige">
			<h4 className="mb-5 font-bold lg:text-28px">{prompt}</h4>
			<div className="flex flex-col gap-2 lg:gap-3">
				<AnswerButton
					answer={answerA}
					isActive={currentAnswer === GQLAnswerChoicesEnum.A}
					clickHandler={() => handleButtonClick(GQLAnswerChoicesEnum.A)}
					isMobile={isMobile}
				/>
				<AnswerButton
					answer={answerB}
					isActive={currentAnswer === GQLAnswerChoicesEnum.B}
					clickHandler={() => handleButtonClick(GQLAnswerChoicesEnum.B)}
					isMobile={isMobile}
				/>
				<AnswerButton
					answer={answerC}
					isActive={currentAnswer === GQLAnswerChoicesEnum.C}
					clickHandler={() => handleButtonClick(GQLAnswerChoicesEnum.C)}
					isMobile={isMobile}
				/>
				<AnswerButton
					answer={answerD}
					isActive={currentAnswer === GQLAnswerChoicesEnum.D}
					clickHandler={() => handleButtonClick(GQLAnswerChoicesEnum.D)}
					isMobile={isMobile}
				/>
				<AnswerButton
					answer={answerE}
					isActive={currentAnswer === GQLAnswerChoicesEnum.E}
					clickHandler={() => handleButtonClick(GQLAnswerChoicesEnum.E)}
					isMobile={isMobile}
				/>
				{isMobile && (
					<div className="px-12 pt-6 pb-2">
						<PetalGraph data={profile?.scores || []} />
					</div>
				)}
			</div>
		</DarkCard>
	);
};

export default MultipleChoice;
