import { create } from "zustand";
import type { GQLProfileObject } from "graphql/generated-types";

interface ProfilesState {
	[id: number]: GQLProfileObject;
}

interface ProfileActions {
	addProfile: (profile: GQLProfileObject) => void;
	getProfile: (id: number) => GQLProfileObject;
	updateScore: (profileId: number, questionId: number, score: number) => void;
}

interface ProfileStore {
	profiles: ProfilesState;
}

export const useProfileStore = create<ProfileStore & ProfileActions>((set, get) => ({
	profiles: {},
	addProfile: (profile: GQLProfileObject) =>
		set((state) => {
			const updatedProfiles = { ...state.profiles };
			updatedProfiles[profile.id] = profile;
			return { profiles: updatedProfiles };
		}),
	getProfile: (id: number) => {
		return get().profiles[id];
	},
	updateScore: (profileId: number, questionId: number, score: number) => {
		set((state) => ({
			profiles: {
				...state.profiles,
				[profileId]: {
					...state.profiles[profileId],
					scores: [
						...state.profiles[profileId].scores.map((q) => {
							if (q?.questionId === questionId) {
								return {
									...q,
									score: score,
								};
							}
							return q;
						}),
					],
				},
			},
		}));
	},
}));
