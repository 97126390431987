"use client";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import Plus from "@/svgs/plus.svg";
import Minus from "@/svgs/minus.svg";

type Props = {
	buttonText: string;
	overflowText: string;
	proseClasses?: string;
};

const OverflowedText = ({ buttonText, overflowText, proseClasses }: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	if (!overflowText) {
		return null;
	}

	const toggleDisclosure = () => {
		setIsOpen(!isOpen);
	};

	const variants = {
		open: {
			opacity: 1,
			maxHeight: 2000,
			transition: { opacity: { duration: 0.3 }, maxHeight: { duration: 0.5, ease: "easeInOut" } },
		},
		closed: {
			opacity: 0,
			maxHeight: 0,
			transition: { opacity: { duration: 0.3 }, maxHeight: { duration: 0.5, ease: "easeInOut" } },
		},
	};

	return (
		<div>
			<button
				className="flex items-center justify-between w-full py-1 mt-6 font-bold border-b border-grey to-lg:text-12px lg:gap-4 lg:w-max"
				onClick={toggleDisclosure}
			>
				{buttonText}
				{isOpen ? <Minus className="w-3" /> : <Plus className="w-3" />}
			</button>
			<AnimatePresence>
				{isOpen && (
					<motion.div initial="closed" animate="open" exit="closed" variants={variants}>
						<div className={clsx("prose pt-6", proseClasses)} dangerouslySetInnerHTML={{ __html: overflowText }} />
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};
export default OverflowedText;
