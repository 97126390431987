"use client";
import React from "react";
import MultipleChoice from "./MultipleChoice";
import OverflowedText from "./OverflowedText";
import type { GQLQuestion } from "graphql/generated-types";

interface Props extends GQLQuestion {
	profileId: number;
}

const Question: React.FC<Props> = ({
	id,
	subject,
	prompt,
	description,
	overflowDescription,
	answerA,
	answerB,
	answerC,
	answerD,
	answerE,
	profileId,
}) => {
	return (
		<div className="flex flex-col items-start gap-10">
			<div className="w-full">
				<h3 className="text-28px">{subject}</h3>
				<div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: description }} />
				<OverflowedText buttonText={`Learn more about ${subject.toLowerCase()}`} overflowText={overflowDescription} />
			</div>
			<MultipleChoice
				id={id}
				prompt={prompt}
				answerA={answerA}
				answerB={answerB}
				answerC={answerC}
				answerD={answerD}
				answerE={answerE}
				profileId={profileId}
			/>
		</div>
	);
};

export default Question;
